<template>
    <div class="main">
        <v-container class="wrapper">
            <v-card 
            width="400">
                <v-card-title class="px-8">Select company to Login</v-card-title>

				<v-card-text class="px-1">
					<v-radio-group
					v-model="selectedTenant"
					mandatory>
						<v-radio v-for="item in tenants" :key="item.slug"
						:label="item.name"
						:value="item"
						:style="selectedTenant==item.slug? 'background: #EEEEEE': 'background: #ffffff'"
						class="ma-0 px-8 py-2">
						</v-radio>
					</v-radio-group>
				</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
					text 
					color="primary" 
					style="margin-bottom: 15px;" 
					@click="selectTenant()"
					:loading="loading" >Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>

		<v-snackbar
                v-model="snackbar.visible"
                :color="snackbar.color"
            >
                {{ snackbar.message }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar.visible = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
    </div>
</template>


<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	data(){
		return {
			permissions: null,
			selectedTenant: null,
			loading: false,
			tenants: [
				// {
				// 	"name": "My company",
				// 	"slug": "dev01"
				// },
				// {
				// 	"name": "Company X",
				// 	"slug": "dev02"
				// },
				// {
				// 	"name": "Company Y",
				// 	"slug": "dev03"
				// },
			],
			snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },
		}
	},
	mounted(){
		this.$emit("set-state", "default");
		this.fetchTenants()
		this.selectedTenant=this.tenants[0]
	},
	methods: {
		fetchTenants(){
			axios({
				method:'get',
				url: appConfig.getApiUrl(null) + "/api/v1/tenants/my-tenants",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}).then((response) => {
				this.tenants=response.data
			})
			.catch((error) => {
				this.loading = false

				// If server is unreachable it won't return a response
				// This case has to be handled
				if (typeof(error.response) === 'undefined'){
					this.snackbar.visible = true;
					this.snackbar.color='error';
					this.snackbar.message='Network error occured. Make sure you are connected to the internet'
					return;
				}

				switch(error.response.status){
					case 400:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Bad request'
						break
					case 401: 
						this.$router.push("/login");
                        break;
					case 429:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Too many requests'
						break;
					case 500:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Server error'
						break;
				}
			});
		},
		fetchTenantPermissions: async function(){
			await axios({
				method:'get',
				url: appConfig.getApiUrl(null) + "/api/v1/tenants/"+ this.selectedTenant.slug+"/my-permissions/",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}).then((response) => {
				this.permissions=response.data
			})
			.catch((error) => {
				this.loading = false

				// If server is unreachable it won't return a response
				// This case has to be handled
				if (typeof(error.response) === 'undefined'){
					this.snackbar.visible = true;
					this.snackbar.color='error';
					this.snackbar.message='Network error occured. Make sure you are connected to the internet'
					return;
				}

				switch(error.response.status){
					case 400:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Bad request'
						break
					case 401: 
						this.$router.push("/login");
                        break;
					case 429:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Too many requests'
						break;
					case 500:
						this.snackbar.visible = true;
						this.snackbar.color='error';
						this.snackbar.message='Server error'
						break;
				}
			});
		},
		processPermissions(permissionData){
			var item;
			var response = [];

			for(let i = 0; i < permissionData.length; i++){
				item = permissionData[i];
				response.push(item.codename);
			}

			return response;
		},
		selectTenant: async function(){
			this.loading=true
			await this.fetchTenantPermissions()
			localStorage.tenantName = this.selectedTenant.name;
			localStorage.tenantSlug = this.selectedTenant.slug;
			//TODO - zapisanie wybranego tenanta jako ulubionego
			
			this.$emit('loggedIn', this.processPermissions(this.permissions), localStorage.user, localStorage.usermail, this.tenants);
		}
	
	}
};
</script>

<style scoped>

.wrapper{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 1904px) { 
	.wrapper {
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 1264px) { 
	.wrapper {
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 960px) { 
	.wrapper {
		transform: translate(0);
	}
}

@media only screen and (max-width: 600px) { 
	.wrapper {
		transform: translate(0);
	}
}



</style>